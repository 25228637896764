import axios from './axios'
import Vue from 'vue'
/**
 * 对老数据做数据适配，如新增的imageTag、audioTag等字段的适配工作
 * @param {*} hb
 */
function hbAdapt(hb) {
    if (!hb) {
        Vue.prototype.$Message.warning('内容数据为空，请联系后台管理人员！');
        throw new Error();
    }
    // 适配老数据: audioTag imageTag
    const articleLen = (hb.mdContentList && hb.mdContentList.length) || 1; // 文章篇数：非韵文 或 韵文时为1
    if (!hb.audioTag || !hb.audioTag.length) {
        hb.audioTag = [];
        for (let i = 0; i < articleLen; i++) {
            hb.audioTag.push([]);
        }
    }
    if (!hb.imageTag || !hb.imageTag.length) {
        hb.imageTag = [];
        for (let i = 0; i < articleLen; i++) {
            hb.imageTag.push([]);
        }
    }
    if (!hb.attributeList) {
        hb.attributeList = [];
    }
    for (let i = 0; i < articleLen; i++) {
        hb.attributeList[i] = Object.assign(
            {
                author: '',
                dynasty: '',
                contentSource: '',
                backgroudUrl: '',
                backgroudKey: '',
                musicKey: '',
                musicUrl: '',
                contentDivList: []
            },
            hb.attributeList[i] || {}
        );
    }
    return hb;
}
export function getSubmitedInfo(params) {
    return axios({
        method: 'get',
        url: '/common/content/hisTaskView',
        params
    }).then(res => {
        res.content.taskName = params.taskName;
        res.content = hbAdapt(res.content);
        return res;
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    });
}

/**
 * 已发布 下架
 * @param {Array<{id,contentType}>} hbList
 * @param {string} reMark
 */
export function offShelf(hbList, reMark) {
    hbList.forEach(hb => {
        hb.remark = reMark;
    });
    return axios({
        method: 'put',
        url: '/common/content/downShelfBatch',
        data: hbList
    });
}
/**
 * 已发布 上架
 * @param {Array<{id,contentType}>} hbList
 */
export function onShelf(hbList) {
    return axios({
        method: 'put',
        url: '/common/content/reUpBatch',
        data: hbList
    });
}
/**
 *
 * @param {Array<{bizId,processInstanceId,taskId,contentType}>} hblist
 * @param {string} reason
 */
export function deleteHbBatch(hblist, reason) {
    const data = hblist.map(ele => {
        return {
            id: ele.bizId || 'bizId',
            processId: ele.processInstanceId || 'processInstanceId',
            taskId: ele.taskId,
            contentType: ele.contentType,
            taskName: ele.taskName,
            pass: -1,
            maxLevel: ele.maxLevel,
            minLevel: ele.minLevel,
            gameType: ele.gameType,
            remark: reason
        };
    });
    return axios({
        method: 'delete',
        url: '/common/content/deleteBath',
        data
    }).catch(err => {
        Vue.prototype.$Message.warning('[后台提示]' + err);
        throw err
    });
}