<template>
  <div class="p-l-md p-r-md">
    <ListFrame
      :immediateInit="false"
      :viewConf="{showReorder:false,itemsDelAble:{delFn:delBatch},showTitle:true,showReset:true}"
      :getPageFn="getPage"
      :customedParams="myParams"
    >
      <template slot-scope="{item}">
        <TeachingItem :info="item" />
        <ButtonsForWaitdealList :hb="item" @to-handle="toInfoPage(item)"/>        
        <span v-if="isAudio&&item.clip" class="text-error">音频匹配失败：部分匹配失败，请点击查看进入修改</span>
      </template>
      <template slot="batch-opterate" slot-scope="{items}">
        <Button
          class="m-l-sm"
          v-if="isAudio"
          :disabled="!items.length"
          type="primary"
          @click="clickUploadAudios(items)"
        >上传音频</Button>
        <Drawer title="选择配音" :mask-closable="false" v-model="showAudioDrawer" width="600">
          <UploadAudioBatch
            v-if="showAudioDrawer"
            @audioUploadFinished="showAudioDrawer=false"
            :hbList="items"
          />
        </Drawer>
      </template>
      <template v-slot:conditions="{requestFn}">
        <div class="flex1" style="text-align:right">
          <ReceiveStatusSelect v-model="myParams.waitStatus" @input="requestFn"
          class="m-r-md"
          style="width:13em;text-align:left"/>
        </div>
      </template>
    </ListFrame>
  </div>
</template>

<script>
import { getList } from './services/teachingService';
import { deleteHbBatch } from './services/api';
export default {
  data() {
    return {
      showAudioDrawer: false,
      myParams:{contentType:'',waitStatus: 2}
    };
  },
  created(){
    this.myParams.contentType=this.laun+'Teaching'
  },
  computed: {
    isAudio() {
      return this.$store.getters.getRoleCode === 5;
    },
    laun() {
      return this.$store.getters.getLaun;
    }
  },
  methods: {
    getPage(params) {
      return getList('waitdeal', params);
    },
    delBatch(items, reason) {
      return deleteHbBatch(items, reason);
    },
    toInfoPage(item) {
      this.$router.replace({
        path: this.$route.path,
        query: Object.assign({}, this.$route.query, { lastId: item.processInstanceId })
      });
      let taskId = item.taskId;
      let bizId = item.bizId;
      let processId = item.processInstanceId;
      let contentType = item.contentType;
      let taskName = item.taskName;

      /**
       * 如果是配音逻辑，就跳转到配音编辑页面
       *
       */
      if (taskName === 5) {
        this.$router.push({
          path: '/edit4audio',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 6) {
        this.$router.push({
          path: '/verify6',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }
      if (taskName === 7) {
        this.$router.push({
          name: 'edit4translate',
          query: { taskId, bizId, processId, contentType }
        });
        return;
      }      
    },
    clickUploadAudios(items) {
      let titles = items.map((ele) => ele.contentTitle[0]);
      let set = new Set(titles);
      if (titles.length > set.size) {
        this.$Message.warning('必须选择标题不同的上传配音！');
        return;
      }
      this.showAudioDrawer = true;
    }
  }
};
</script>

<style>
</style>